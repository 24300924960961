import * as React from "react"
import Layout from "../components/layout"
import Helmet from "react-helmet"


// markup
const VerhuurPage = () => {

  return (
    <Layout pageTitle={'Verhuur'}>
        <div class="px-2 md:px-0 py-4 flickr">
          <a data-flickr-embed="true" data-header="true" data-footer="false" href="https://www.flickr.com/photos/145759614@N08/albums/72157676148276400" title="VERHUUR">
            <img class="mx-auto max-w-3xl w-full" src="https://live.staticflickr.com/5742/30428453194_ac3e8c2444_h.jpg" alt="VERHUUR"></img>
          </a>
          <Helmet><script async src="//embedr.flickr.com/assets/client-code.js" charset="utf-8"></script></Helmet>
        </div>
        <div class="px-8 md:px-4 py-6 flickr">
          <h2 class="text-4xl max-w-fit font-bold py-2"></h2>
          <h2 class="text-4xl max-w-fit font-bold py-2">Prijs en reservatie</h2>
          <p>Wij verhuren onze lokalen <strong>niet</strong> als kampplaats en niet aan aspi-,keti of leidingsgroepen! Dit betekent dat er enkel verhuurd wordt aan groepen tot 14 jaar.</p>
          <ul class="list-disc px-4">
            <li>&euro;35 per nacht + &euro;3 per persoon per nacht met een minimum van &euro;250 ookal is de groep kleiner</li>
            <li>&euro;300 waarborg</li>
            <li>Gelieve eerst de verhuurkalender te controleren alvorens de verantwoordelijke te contacteren<br />Dien een verhuuraanvraag in via mail en verdere instructies worden opgestuurd.</li>
            <li>Er is plaats voor 40 personen maar eigen tenten zijn toegelaten.</li>
            <li>Het aantal opgegeven personen is het minimum aantal dat wordt aangerekend bij de afrekening van het verblijf.</li>
            <li>Indien u de overeenkomst opzegt minder dan 2 weken voor vrijdag 20u, dan wordt de volledige waarborg ingetrokken.</li>
            <li>Indien u een aanvraag wilt indienen, stuur dan een email naar&nbsp;<a class="underline text-red-500 decoration-red-500" href="mailto:verhuur@chirodoeritsan.be">verhuur@chirodoeritsan.be</a>, check zeker ook uw spam folder!</li>
            <li><a class="underline text-red-500 decoration-red-500" href="/verhuur/verhuurcontract.pdf">Hier</a> vindt u het verhuurcontract.</li>
          </ul>
        </div>
        <div class="py-4">
        </div>
    </Layout>
  )
}

export default VerhuurPage
